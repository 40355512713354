<template>
  <div class="added-courses">
    <div class="container">
      <section class="added-courses__inner">
        <base-heading bold="bold" class="dashboard-heading">{{ $t("myCourses.author.heading") }}</base-heading>

        <base-table :columns="columns" :data="courses" class="added-courses__table">
          <template slot-scope="{ row, index }">
            <td>{{ index + 1 }}.</td>
            <td style="max-width: 250px;">
              <course-thumbnail
                :id="index"
                :additionalDetails="`${$t('level')} ${row.difficulty}`"
                v-bind="row"
                style="max-width: 250px;"
              />
            </td>
            <td>{{ row.soldCount }}</td>
            <td>
              {{
                row.status === "published"
                  ? $t("myCourses.author.table.published")
                  : $t("myCourses.author.table.draft")
              }}
            </td>
            <td>
              <div class="added-courses__table-actions">
                <base-button
                  v-if="row.status === 'not_published'"
                  thin
                  class="added-courses__table-button added-courses__table-button--publish"
                  :isLoading="isPublishing === row.id"
                  @click="publishCourse(row)"
                >{{ $t("myCourses.author.publish") }}</base-button>
                <base-button
                  type="outline-gray"
                  :to="`/manage-course/${row.id}`"
                  :title="$t('myCourses.author.table.settings')"
                  thin
                  class="added-courses__table-button added-courses__table-button--edit"
                >{{ $t("courseSummary.edit") }}</base-button>
                <button
                  type="button"
                  class="added-courses__table-button-delete"
                  :title="
                    isMutable(row)
                      ? $t('myCourses.author.table.delete')
                      : $t('myCourses.author.table.immutable')
                  "
                  @click="handleShowModal(row)"
                  :class="
                    !isMutable(row) &&
                    'added-courses__table-button-delete--hidden'
                  "
                >
                  <svg-trash fill="#A0A4A8" class="added-courses__table-button-delete-icon" />
                </button>
              </div>
            </td>
            <td>
              <base-tooltip direction="left" v-if="setStatusIcon(row.lessons)">
                <template v-slot:element>
                  <img
                    class="added-courses__table-status"
                    :src="`/assets/icons/${setStatusIcon(row.lessons)}.svg`"
                  />
                </template>
                <template v-slot:content>
                  <p>{{ setStatusTitle(row.lessons) }}</p>
                </template>
              </base-tooltip>
            </td>
            <td>
              <div class="inline-column">
                <base-input
                  :hideArrows="true"
                  v-if="!isPromotionActive(row)"
                  @input="checkPromotionValue(row)"
                  type="number"
                  min="0"
                  max="100"
                  rules="min_value:0|max_value:100"
                  v-model="row.promotionValue"
                  style="max-width: 100px; margin:0"
                />
                <span v-else>{{ row.promotionValue }}</span>
                <svg
                  class="icon-indicator"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="percent"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    :fill="isPromotionActive(row) ? '#48EFA7' : 'black'"
                    d="M112 224c61.9 0 112-50.1 112-112S173.9 0 112 0 0 50.1 0 112s50.1 112 112 112zm0-160c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm224 224c-61.9 0-112 50.1-112 112s50.1 112 112 112 112-50.1 112-112-50.1-112-112-112zm0 160c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zM392.3.2l31.6-.1c19.4-.1 30.9 21.8 19.7 37.8L77.4 501.6a23.95 23.95 0 0 1-19.6 10.2l-33.4.1c-19.5 0-30.9-21.9-19.7-37.8l368-463.7C377.2 4 384.5.2 392.3.2z"
                  />
                </svg>
              </div>
            </td>
            <td>
              <div class="inline-column">
                <div style="margin-right: 12px">{{ getPromotionEnd(row) }}</div>
                <base-select
                  v-if="!isPromotionActive(row)"
                  :disabled="!(+row.promotionValue > 0)"
                  @change="updateData(row, { promotionEnd: setPromotionEnd($event), promotionValue: +row.promotionValue })"
                >
                  <template v-slot:items>
                    <base-option
                      :label="hour + 'h'"
                      :value="hour"
                      :key="index"
                      v-for="(hour,index) in promotionHours"
                    >{{ hour }}h</base-option>
                  </template>
                </base-select>
                <svg
                  class="icon-indicator"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="clock"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    :fill="isPromotionActive(row) ? '#48EFA7' : 'black'"
                    d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm61.8-104.4l-84.9-61.7c-3.1-2.3-4.9-5.9-4.9-9.7V116c0-6.6 5.4-12 12-12h32c6.6 0 12 5.4 12 12v141.7l66.8 48.6c5.4 3.9 6.5 11.4 2.6 16.8L334.6 349c-3.9 5.3-11.4 6.5-16.8 2.6z"
                  />
                </svg>
              </div>
            </td>
          </template>
        </base-table>

        <base-button
          type="contrasting"
          class="added-courses__button"
          to="/add-course"
        >{{ $t("myCourses.author.addNew") }}</base-button>

        <base-modal
          :value="showModal"
          class="image-modal"
          @input="() => (this.showModal = false)"
          @accept="handleDelete"
          :title="$t('myCourses.author.delete.heading')"
        >
          <div slot="content">
            <p>{{ $t("myCourses.author.delete.text") }}</p>
            <course-thumbnail
              v-if="deletedCourse"
              :id="deletedCourse.id"
              :name="deletedCourse.name"
              :additionalDetails="`${$t('level')} ${deletedCourse.difficulty}`"
              class="added-courses__deleted-course"
              :slug="deletedCourse.slug"
              :imageId="deletedCourse.imageId"
            />
          </div>
        </base-modal>
      </section>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash.debounce';

import course from '@/api/services/course';

export default {
  name: 'AddedCourses',
  data: () => ({
    promotionHours: [24, 48, 72],
    courses: [],
    showModal: false,
    deletedCourse: null,
    isPublishing: false,
  }),
  computed: {
    columns() {
      const keys = [
        'no',
        'name',
        'sold',
        'published',
        'actions',
        'status',
        'promotion',
        'time',
      ];
      return this.$t('myCourses.author.table.columns').map((column, index) => ({
        key: keys[index],
        label: column,
      }));
    },
    hasCourses() {
      return this.courses.length > 0;
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    checkPromotionValue: debounce(function (singleCourse) {
      let newPrice = singleCourse.price - (singleCourse.price * (singleCourse.promotionValue / 100));
      if (newPrice < 500) { // product cant cost less than 5 eur in eurocents
        //  update the promotionValue
        const index = this.courses.findIndex((x) => x.id === singleCourse.id);
        let i = singleCourse.promotionValue;
        for (i; i > 0; i -= 1) {
          newPrice = singleCourse.price - (singleCourse.price * (i / 100));
          if (newPrice >= 500) {
            break;
          }
        }
        this.courses = [...this.courses.slice(0, index), { ...singleCourse, promotionValue: i }, ...this.courses.slice(index + 1, this.courses.length)];
      }
    }, 100),
    isPromotionActive(singleCourse) {
      const date = Date.parse(singleCourse.promotionEnd);
      if (Number.isNaN(date) || date < new Date()) {
        return false;
      }
      return true;
    },
    getPromotion(singleCourse) {
      return +singleCourse.promotionValue > 0 ? (`${singleCourse.promotionValue}%`) : '';
    },
    getPromotionEnd(singleCourse) {
      if (!this.isPromotionActive(singleCourse)) {
        return '';
      }
      const date = Date.parse(singleCourse.promotionEnd);
      const local = new Date();
      const now = new Date(local.getTime() + local.getTimezoneOffset() * 60000);
      const diff = (date - now);
      return `${Math.ceil(Math.abs((diff) / 36e5))}h`;
    },
    setPromotionEnd(hours) {
      const local = new Date();
      const now = new Date(local.getTime() + local.getTimezoneOffset() * 60000);
      now.setHours(now.getHours() + hours.value);
      return now;
    },
    getCommision(singleCourse) {
      return (
        (1 - singleCourse.commissionRate / 100)
        * singleCourse.soldCount
        * singleCourse.price
      );
    },
    async updateData(singleCourse, changes) {
      const index = this.courses.findIndex((x) => x.id === singleCourse.id);
      // send
      const { data } = await course.updateCourse({ ...singleCourse, ...changes },
        singleCourse.id,
      );
      this.courses = [...this.courses.slice(0, index), data, ...this.courses.slice(index + 1, this.courses.length)];
    },
    async publishCourse(singleCourse) {
      this.isPublishing = singleCourse.id;

      try {
        const { data } = await course.updateCourse(
          { ...singleCourse, status: 'published' },
          singleCourse.id,
        );

        this.courses = this.courses.map((existingCourse) => (existingCourse.id === data.id ? { ...data } : existingCourse),
        );
        this.$store.dispatch('notification/spawn', {
          title: this.$t('addCourse.success.title'),
          text: this.$t('addCourse.success.desc'),
          type: 'success',
        });
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.isPublishing = false;
      }
    },
    async handleDelete() {
      this.showModal = false;
      const result = await course.deleteCourse(this.deletedCourse.id);

      if (result.status === 200) {
        this.courses = this.courses.filter(
          (singleCourse) => singleCourse.id !== this.deletedCourse.id,
        );

        this.$store.dispatch('notification/spawn', {
          type: 'success',
          title: this.$t('deleteCourse.success'),
          text: this.$t('deleteCourse.text'),
        });
      }
    },
    checkAssetsStatus(lessons) {
      const statuses = [...new Set(lessons.map((lesson) => lesson.assetStatus))];

      if (statuses.includes('errored')) return 'errored';
      if (
        statuses.includes('processing')
        || statuses.includes('awaiting_upload')
      ) return 'processing';
      if (statuses.includes('none')) return 'none';
      if (statuses.includes('processed')) return 'processed';

      return '';
    },
    handleShowModal(deletedCourse) {
      this.showModal = true;
      this.deletedCourse = deletedCourse;
    },
    setStatusTitle(lessons) {
      const status = this.checkAssetsStatus(lessons);

      switch (status) {
        case 'errored': {
          return this.$t('myCourses.author.status.errored');
        }
        case 'processing': {
          return this.$t('myCourses.author.status.processing');
        }
        case 'processed': {
          return this.$t('myCourses.author.status.processed');
        }
        default:
          return '';
      }
    },
    setStatusIcon(lessons) {
      const status = this.checkAssetsStatus(lessons);

      switch (status) {
        case 'errored': {
          return 'error';
        }
        case 'processing': {
          return 'pending';
        }
        case 'processed': {
          return 'check-new';
        }
        default: {
          return '';
        }
      }
    },
    isMutable(singleCourse) {
      return (
        singleCourse.status === 'not_published'
        || (singleCourse.soldCount === 0 && singleCourse.status === 'published')
      );
    },
  },
  async created() {
    this.$store.dispatch('ui/setPreloader', true);

    try {
      const { data } = await course.getCreatorCourses();
      this.courses = data;
    } catch (err) {
      this.$store.dispatch('notification/spawn', {
        errorMessage: err.response.data.message,
        type: 'error',
      });
    } finally {
      this.$store.dispatch('ui/setPreloader', false);
    }
  },
};
</script>

<style lang="scss" scoped src="./AddedCourses.scss"/>
